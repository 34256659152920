import { SanitisedMegaNav, MegaNavContentType } from './types';

import { SupportedLocation } from '@/modules/location/constants';

function MEGANAV_UK(cloudfrontHost: string): SanitisedMegaNav {
  return [
    {
      label: 'Menswear',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Shop by category',
            footer: {
              label: 'See all menswear',
              path: '/category/mens/',
              colourOverride: null,
            },
            itemsCollection: {
              items: [
                {
                  label: 'Tops',
                  path: '/category/mens/tops/',
                },
                { label: 'Bottoms', path: '/category/mens/bottoms/' },
                { label: 'Coats & Jackets', path: '/category/mens/outerwear/' },
                { label: 'Shoes', path: '/category/mens/shoes/' },
                { label: 'Accessories', path: '/category/mens/accessories/' },
                { label: 'Jewellery', path: '/category/jewellery/' },
                { label: 'T-shirts', path: '/category/mens/tops/t-shirts/' },
                {
                  label: 'Sale',
                  path: '/category/mens/?isDiscounted=true',
                  colourOverride: 'red',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Featured',
            itemsCollection: {
              items: [
                { label: 'Hoodies', path: '/category/mens/tops/hoodies/' },
                {
                  label: 'Sweatshirts',
                  path: '/category/mens/tops/sweatshirts/',
                },
                { label: 'Jeans', path: '/category/mens/bottoms/jeans/' },
                { label: 'Shorts', path: '/category/mens/bottoms/shorts/' },
                {
                  label: 'Sweatpants',
                  path: '/category/mens/bottoms/joggers/',
                },
                { label: 'Hats', path: '/category/mens/accessories/hats/' },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Jumpers',
                  path: '/category/mens/tops/sweatshirts/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/jumpers.webp`,
                    description: 'Jumpers',
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/jumpers_mobile.webp`,
                    description: 'Jumpers',
                  },
                },
                {
                  label: 'Trainers',
                  path: '/category/mens/shoes/sneakers/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/trainers.webp`,
                    description: 'Trainers',
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/trainers_mobile.webp`,
                    description: 'Trainers',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Womenswear',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Shop by category',
            footer: { label: 'See all womenswear', path: '/category/womens/' },
            itemsCollection: {
              items: [
                { label: 'Tops', path: '/category/womens/tops/' },
                { label: 'Bottoms', path: '/category/womens/bottoms/' },
                { label: 'Dresses', path: '/category/womens/dresses/' },
                {
                  label: 'Coats & Jackets',
                  path: '/category/womens/outerwear/',
                },
                { label: 'Shoes', path: '/category/womens/shoes/' },
                { label: 'Accessories', path: '/category/womens/accessories/' },
                { label: 'Jewellery', path: '/category/jewellery/' },
                {
                  label: 'Sale',
                  path: '/category/womens/?isDiscounted=true',
                  colourOverride: 'red',
                },
              ],
            },
          },
          {
            title: 'Featured',
            __typename: MegaNavContentType.CategoryList,
            itemsCollection: {
              items: [
                {
                  label: 'Cargo trousers',
                  path: '/search/?q=cargo+trousers&categories=10',
                },
                { label: 'T-shirts', path: '/category/womens/tops/t-shirts/' },
                { label: 'Jumpers', path: '/category/womens/tops/sweaters/' },
                { label: 'Skirts', path: '/category/womens/bottoms/skirts/' },
                { label: 'Trainers', path: '/category/womens/shoes/sneakers/' },
                {
                  label: 'Bags & Purses',
                  path: '/category/womens/accessories/bags-and-purses/',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Puffer jackets',
                  path: '/search/?q=puffer+jacket&categories=13&sort=relevance',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/puffer_jacket.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/puffer_jacket_mobile.webp`,
                  },
                },
                {
                  label: 'Bags & Purses',
                  path: '/category/womens/accessories/bags-and-purses/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/bags_and_purses.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/bags_and_purses_mobile.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Brands',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Popular brands',
            footer: { label: 'See all brands', path: '/brands/' },
            itemsCollection: {
              items: [
                { label: 'Adidas', path: '/brands/adidas/' },
                { label: 'Brandy Melville', path: '/brands/brandy-melville/' },
                { label: 'Carhartt', path: '/brands/carhartt/' },
                { label: 'Converse', path: '/brands/converse/' },
                { label: 'Corteiz', path: '/brands/corteiz/' },
                { label: 'Dr. Martens', path: '/brands/dr-martens/' },
                { label: 'Free People', path: '/brands/free-people/' },
                { label: 'Gymshark', path: '/brands/gymshark/' },
                { label: 'H&M', path: '/brands/h-m/' },
                { label: 'Jordan', path: '/brands/jordan/' },
                { label: `Levi's`, path: '/brands/levi-s/' },
                { label: 'Lululemon', path: '/brands/lululemon/' },
                { label: 'New Balance', path: '/brands/new-balance/' },
                { label: 'Nike', path: '/brands/nike/' },
                { label: 'Patagonia', path: '/brands/patagonia/' },
                { label: 'Stüssy', path: '/brands/stussy/' },
                {
                  label: 'Urban Outfitters',
                  path: '/brands/urban-outfitters/',
                },
                { label: 'Zara', path: '/brands/zara/' },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Carhartt',
                  path: '/brands/carhartt/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/carhartt.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/carhartt.webp`,
                  },
                },
                {
                  label: 'New Balance',
                  path: '/brands/new-balance/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/new_balance.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/new_balance.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Sale',
      colourOverride: 'red',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: `Women's sale`,
            footer: {
              label: `See all women's sale`,
              path: '/category/womens/?isDiscounted=true',
            },
            itemsCollection: {
              items: [
                {
                  label: 'Tops',
                  path: '/category/womens/tops/?isDiscounted=true',
                },
                {
                  label: 'Bottoms',
                  path: '/category/womens/bottoms/?isDiscounted=true',
                },
                {
                  label: 'Dresses',
                  path: '/category/womens/dresses/?isDiscounted=true',
                },
                {
                  label: 'Coats & Jackets',
                  path: '/category/womens/outerwear/?isDiscounted=true',
                },
                {
                  label: 'Shoes',
                  path: '/category/womens/shoes/?isDiscounted=true',
                },
                {
                  label: 'Accessories',
                  path: '/category/womens/accessories/?isDiscounted=true',
                },
                {
                  label: 'Crop tops',
                  path: '/category/womens/tops/crop-tops/?isDiscounted=true',
                },
                {
                  label: 'T-shirts',
                  path: '/category/womens/tops/t-shirts/?isDiscounted=true',
                },
                {
                  label: 'Skirts',
                  path: '/category/womens/bottoms/skirts/?isDiscounted=true',
                },
                {
                  label: 'Going out dresses',
                  path: '/category/womens/dresses/going-out-dresses/?isDiscounted=true',
                },
                {
                  label: 'Hoodies',
                  path: '/category/womens/tops/hoodies/?isDiscounted=true',
                },
                {
                  label: 'Trainers',
                  path: '/category/womens/shoes/sneakers/?isDiscounted=true',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.CategoryList,
            title: `Men's sale`,
            footer: {
              label: `See all men's sale`,
              path: '/category/mens/?isDiscounted=true',
            },
            itemsCollection: {
              items: [
                {
                  label: 'Tops',
                  path: '/category/mens/tops/?isDiscounted=true',
                },
                {
                  label: 'Bottoms',
                  path: '/category/mens/bottoms/?isDiscounted=true',
                },
                {
                  label: 'Coats & Jackets',
                  path: '/category/mens/outerwear/?isDiscounted=true',
                },
                {
                  label: 'Shoes',
                  path: '/category/mens/shoes/?isDiscounted=true',
                },
                {
                  label: 'Accessories',
                  path: '/category/mens/accessories/?isDiscounted=true',
                },
                {
                  label: 'T-shirts',
                  path: '/category/mens/tops/t-shirts/?isDiscounted=true',
                },
                {
                  label: 'Hoodies',
                  path: '/category/mens/tops/hoodies/?isDiscounted=true',
                },
                {
                  label: 'Sweatshirts',
                  path: '/category/mens/tops/sweatshirts/?isDiscounted=true',
                },
                {
                  label: 'Jeans',
                  path: '/category/mens/bottoms/jeans/?isDiscounted=true',
                },
                {
                  label: 'Cargo trousers',
                  path: '/search/?q=mens+cargo+pants&categories=3&isDiscounted=true',
                },
                {
                  label: 'Trainers',
                  path: '/category/mens/shoes/sneakers/?isDiscounted=true',
                },
                {
                  label: 'Hats',
                  path: '/category/mens/accessories/hats/?isDiscounted=true',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Under £100',
                  path: '/explore/under-100',
                  hideLabel: true,
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/sale_100.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/sale_100.webp`,
                  },
                },
                {
                  label: 'Under £50',
                  path: '/explore/under-50',
                  hideLabel: true,
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/sale_50.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/sale_50.webp`,
                  },
                },
                {
                  label: 'Under £20',
                  path: '/explore/under-20',
                  hideLabel: true,
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/sale_20.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/sale_20.webp`,
                  },
                },
                {
                  label: 'Under £10',
                  path: '/explore/under-10',
                  hideLabel: true,
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/sale_10.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/sale_10.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ];
}

function MEGANAV_IT(cloudfrontHost: string): SanitisedMegaNav {
  return [
    {
      label: 'Abbigliamento uomo',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Acquista per categoria',
            footer: {
              label: 'Mostra tutto',
              path: '/category/mens/',
            },
            itemsCollection: {
              items: [
                { label: 'Dalla cinta in su', path: '/category/mens/tops/' },
                {
                  label: 'Dalla cinta in giù',
                  path: '/category/mens/bottoms/',
                },
                { label: 'Intimo', path: '/category/mens/outerwear/' },
                { label: 'Scarpe', path: '/category/mens/shoes/' },
                { label: 'Accessori', path: '/category/mens/accessories/' },
                { label: 'Gioielli', path: '/category/jewellery/' },
                { label: 'T-shirt', path: '/category/mens/tops/t-shirts/' },
                {
                  label: 'Vendita',
                  path: '/category/mens/?isDiscounted=true',
                  colourOverride: 'red',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'In primo piano',
            itemsCollection: {
              items: [
                {
                  label: 'Felpe con cappuccio',
                  path: '/category/mens/tops/hoodies/',
                },
                { label: 'Felpe', path: '/category/mens/tops/sweatshirts/' },
                { label: 'Jeans', path: '/category/mens/bottoms/jeans/' },
                {
                  label: 'Pantaloncini',
                  path: '/category/mens/bottoms/shorts/',
                },
                {
                  label: 'Scarpe de ginnastica',
                  path: '/category/mens/shoes/sneakers/',
                },
                { label: 'Cappelli', path: '/category/mens/accessories/hats/' },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Dalla cinta in su',
                  path: '/category/mens/tops/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/tops.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/tops.webp`,
                  },
                },
                {
                  label: 'Pantaloncini',
                  path: '/category/mens/bottoms/shorts',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/shorts.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/shorts.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Abbigliamento donna',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Acquista per categoria',
            footer: { label: 'Mostra tutto', path: '/category/womens/' },
            itemsCollection: {
              items: [
                { label: 'Dalla cinta in su', path: '/category/womens/tops/' },
                {
                  label: 'Dalla cinta in giù',
                  path: '/category/womens/bottoms/',
                },
                { label: 'Abiti', path: '/category/womens/dresses/' },
                { label: 'Intimo', path: '/category/womens/outerwear/' },
                { label: 'Scarpe', path: '/category/womens/shoes/' },
                { label: 'Accessori', path: '/category/womens/accessories/' },
                { label: 'Gioielli', path: '/category/jewellery/' },
                {
                  label: 'Vendita',
                  path: '/category/womens/?isDiscounted=true',
                  colourOverride: 'red',
                },
              ],
            },
          },
          {
            title: 'In primo piano',
            __typename: MegaNavContentType.CategoryList,
            itemsCollection: {
              items: [
                {
                  label: 'Cropped top',
                  path: '/category/womens/tops/crop-tops/',
                },
                { label: 'T-shirt', path: '/category/womens/tops/t-shirts/' },
                {
                  label: 'Pantaloncini',
                  path: '/category/womens/bottoms/shorts/',
                },
                { label: 'Gonne', path: '/category/womens/bottoms/skirts/' },
                {
                  label: 'Scarpe de ginnastica',
                  path: '/category/womens/shoes/sneakers/',
                },
                {
                  label: 'Borse e borsellini',
                  path: '/category/womens/accessories/bags-and-purses/',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Collo all’americana',
                  path: '/search/?q=halter+top&categories=9&sort=relevance',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/halter_top.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/halter_top.webp`,
                  },
                },
                {
                  label: 'Stivali',
                  path: '/category/womens/shoes/boots/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/boots.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/boots.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Brand',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Brand popolari',
            footer: { label: 'Mostra tutto', path: '/brands/' },
            itemsCollection: {
              items: [
                { label: 'Adidas', path: '/brands/adidas/' },
                { label: 'Brandy Melville', path: '/brands/brandy-melville/' },
                { label: 'Carhartt', path: '/brands/carhartt/' },
                { label: 'Converse', path: '/brands/converse/' },
                { label: 'Corteiz', path: '/brands/corteiz/' },
                { label: 'Dr. Martens', path: '/brands/dr-martens/' },
                { label: 'Free People', path: '/brands/free-people/' },
                { label: 'Gymshark', path: '/brands/gymshark/' },
                { label: 'H&M', path: '/brands/h-m/' },
                { label: 'Jordan', path: '/brands/jordan/' },
                { label: `Levi's`, path: '/brands/levi-s/' },
                { label: 'Lululemon', path: '/brands/lululemon/' },
                { label: 'New Balance', path: '/brands/new-balance/' },
                { label: 'Nike', path: '/brands/nike/' },
                { label: 'Patagonia', path: '/brands/patagonia/' },
                { label: 'Stüssy', path: '/brands/stussy/' },
                {
                  label: 'Urban Outfitters',
                  path: '/brands/urban-outfitters/',
                },
                { label: 'Zara', path: '/brands/zara/' },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Carhartt',
                  path: '/brands/carhartt/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/carhartt.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/carhartt.webp`,
                  },
                },
                {
                  label: 'New Balance',
                  path: '/brands/new-balance/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/new_balance.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/new_balance.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Vendita',
      colourOverride: 'red',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: `Saldi da donna`,
            footer: {
              label: `Mostra tutto`,
              path: '/category/womens/?isDiscounted=true',
            },
            itemsCollection: {
              items: [
                {
                  label: 'Dalla cinta in su',
                  path: '/category/womens/tops/?isDiscounted=true',
                },
                {
                  label: 'Dalla cinta in giù',
                  path: '/category/womens/bottoms/?isDiscounted=true',
                },
                {
                  label: 'Abiti',
                  path: '/category/womens/dresses/?isDiscounted=true',
                },
                {
                  label: 'Intimo',
                  path: '/category/womens/outerwear/?isDiscounted=true',
                },
                {
                  label: 'Scarpe',
                  path: '/category/womens/shoes/?isDiscounted=true',
                },
                {
                  label: 'Accessori',
                  path: '/category/womens/accessories/?isDiscounted=true',
                },
                {
                  label: 'Cropped top',
                  path: '/category/womens/tops/crop-tops/?isDiscounted=true',
                },
                {
                  label: 'T-shirt',
                  path: '/category/womens/tops/t-shirts/?isDiscounted=true',
                },
                {
                  label: 'Gonne',
                  path: '/category/womens/bottoms/skirts/?isDiscounted=true',
                },
                {
                  label: 'Abiti estivi ',
                  path: '/category/womens/dresses/summer-dresses/?isDiscounted=true',
                },
                {
                  label: 'Pantaloncini',
                  path: '/category/womens/bottoms/shorts/?isDiscounted=true',
                },
                {
                  label: 'Scarpe de ginnastica',
                  path: '/category/womens/shoes/sneakers/?isDiscounted=true',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.CategoryList,
            title: `Saldi da uomo`,
            footer: {
              label: `Mostra tutto`,
              path: '/category/mens/?isDiscounted=true',
            },
            itemsCollection: {
              items: [
                {
                  label: 'Dalla cinta in su',
                  path: '/category/mens/tops/?isDiscounted=true',
                },
                {
                  label: 'Dalla cinta in giù',
                  path: '/category/mens/bottoms/?isDiscounted=true',
                },
                {
                  label: 'Intimo',
                  path: '/category/mens/outerwear/?isDiscounted=true',
                },
                {
                  label: 'Scarpe',
                  path: '/category/mens/shoes/?isDiscounted=true',
                },
                {
                  label: 'Accessori',
                  path: '/category/mens/accessories/?isDiscounted=true',
                },
                {
                  label: 'T-shirt',
                  path: '/category/mens/tops/t-shirts/?isDiscounted=true',
                },
                {
                  label: 'Felpe con cappuccio',
                  path: '/category/mens/tops/hoodies/?isDiscounted=true',
                },
                {
                  label: 'Felpe',
                  path: '/category/mens/tops/sweatshirts/?isDiscounted=true',
                },
                {
                  label: 'Jeans',
                  path: '/category/mens/bottoms/jeans/?isDiscounted=true',
                },
                {
                  label: 'Pantaloncini',
                  path: '/category/mens/bottoms/shorts/?isDiscounted=true',
                },
                {
                  label: 'Scarpe de ginnastica',
                  path: '/category/mens/shoes/sneakers/?isDiscounted=true',
                },
                {
                  label: 'Cappelli',
                  path: '/category/mens/accessories/hats/?isDiscounted=true',
                },
              ],
            },
          },
        ],
      },
    },
  ];
}

function MEGANAV_FR(cloudfrontHost: string): SanitisedMegaNav {
  return [
    {
      label: 'Vêtements pour hommes',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Acheter par catégorie',
            footer: { label: 'Alle ansehen', path: '/category/mens/' },
            itemsCollection: {
              items: [
                { label: 'Hauts', path: '/category/mens/tops/' },
                { label: 'Bas', path: '/category/mens/bottoms/' },
                {
                  label: 'Vêtements d’extérieur',
                  path: '/category/mens/outerwear/',
                },
                { label: 'Chaussures', path: '/category/mens/shoes/' },
                { label: 'Accessories', path: '/category/mens/accessories/' },
                { label: 'Bijoux', path: '/category/jewellery/' },
                { label: 'T-shirts', path: '/category/mens/tops/t-shirts/' },
                {
                  label: 'Vente',
                  path: '/category/mens/?isDiscounted=true',
                  colourOverride: 'red',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'En Vedette',
            itemsCollection: {
              items: [
                {
                  label: 'Sweats à capuche',
                  path: '/category/mens/tops/hoodies/',
                },
                {
                  label: 'Sweat-shirts',
                  path: '/category/mens/tops/sweatshirts/',
                },
                { label: 'Jeans', path: '/category/mens/bottoms/jeans/' },
                { label: 'Shorts', path: '/category/mens/bottoms/shorts/' },
                { label: 'Baskets', path: '/category/mens/shoes/sneakers/' },
                { label: 'Chapeaux', path: '/category/mens/accessories/hats/' },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Hauts',
                  path: '/category/mens/tops/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/tops.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/tops.webp`,
                  },
                },
                {
                  label: 'Shorts',
                  path: '/category/mens/bottoms/shorts',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/shorts.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/shorts.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Vêtements pour femmes',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Acheter par catégorie',
            footer: { label: 'Alle ansehen', path: '/category/womens/' },
            itemsCollection: {
              items: [
                { label: 'Hauts', path: '/category/womens/tops/' },
                { label: 'Bas', path: '/category/womens/bottoms/' },
                { label: 'Robes', path: '/category/womens/dresses/' },
                {
                  label: 'Vêtements d’extérieur',
                  path: '/category/womens/outerwear/',
                },
                { label: 'Chaussures', path: '/category/womens/shoes/' },
                { label: 'Accessories', path: '/category/womens/accessories/' },
                { label: 'Bijoux', path: '/category/jewellery/' },
                {
                  label: 'Vente',
                  path: '/category/womens/?isDiscounted=true',
                  colourOverride: 'red',
                },
              ],
            },
          },
          {
            title: 'En Vedette',
            __typename: MegaNavContentType.CategoryList,
            itemsCollection: {
              items: [
                {
                  label: 'Crop tops',
                  path: '/category/womens/tops/crop-tops/',
                },
                { label: 'T-shirts', path: '/category/womens/tops/t-shirts/' },
                { label: 'Shorts', path: '/category/womens/bottoms/shorts/' },
                { label: 'Jupes', path: '/category/womens/bottoms/skirts/' },
                { label: 'Baskets', path: '/category/womens/shoes/sneakers/' },
                {
                  label: 'Sacs et porte-monnaie',
                  path: '/category/womens/accessories/bags-and-purses/',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Dos nu',
                  path: '/search/?q=halter+top&categories=9&sort=relevance',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/halter_top.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/halter_top.webp`,
                  },
                },
                {
                  label: 'Bottes',
                  path: '/category/womens/shoes/boots/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/boots.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/boots.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Marques',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Marques populaires',
            footer: { label: 'Alle ansehen', path: '/brands/' },
            itemsCollection: {
              items: [
                { label: 'Adidas', path: '/brands/adidas/' },
                { label: 'Brandy Melville', path: '/brands/brandy-melville/' },
                { label: 'Carhartt', path: '/brands/carhartt/' },
                { label: 'Converse', path: '/brands/converse/' },
                { label: 'Corteiz', path: '/brands/corteiz/' },
                { label: 'Dr. Martens', path: '/brands/dr-martens/' },
                { label: 'Free People', path: '/brands/free-people/' },
                { label: 'Gymshark', path: '/brands/gymshark/' },
                { label: 'H&M', path: '/brands/h-m/' },
                { label: 'Jordan', path: '/brands/jordan/' },
                { label: `Levi's`, path: '/brands/levi-s/' },
                { label: 'Lululemon', path: '/brands/lululemon/' },
                { label: 'New Balance', path: '/brands/new-balance/' },
                { label: 'Nike', path: '/brands/nike/' },
                { label: 'Patagonia', path: '/brands/patagonia/' },
                { label: 'Stüssy', path: '/brands/stussy/' },
                {
                  label: 'Urban Outfitters',
                  path: '/brands/urban-outfitters/',
                },
                { label: 'Zara', path: '/brands/zara/' },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Carhartt',
                  path: '/brands/carhartt/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/carhartt.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/carhartt.webp`,
                  },
                },
                {
                  label: 'New Balance',
                  path: '/brands/new-balance/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/new_balance.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/new_balance.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Vente',
      colourOverride: 'red',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: `Promotions sur les vêtements pour femmes`,
            footer: {
              label: `Alle ansehen`,
              path: '/category/womens/?isDiscounted=true',
            },
            itemsCollection: {
              items: [
                {
                  label: 'Hauts',
                  path: '/category/womens/tops/?isDiscounted=true',
                },
                {
                  label: 'Bas',
                  path: '/category/womens/bottoms/?isDiscounted=true',
                },
                {
                  label: 'Robes',
                  path: '/category/womens/dresses/?isDiscounted=true',
                },
                {
                  label: 'Vêtements d’extérieur',
                  path: '/category/womens/outerwear/?isDiscounted=true',
                },
                {
                  label: 'Chaussures',
                  path: '/category/womens/shoes/?isDiscounted=true',
                },
                {
                  label: 'Accessories',
                  path: '/category/womens/accessories/?isDiscounted=true',
                },
                {
                  label: 'Crop tops',
                  path: '/category/womens/tops/crop-tops/?isDiscounted=true',
                },
                {
                  label: 'T-shirts',
                  path: '/category/womens/tops/t-shirts/?isDiscounted=true',
                },
                {
                  label: 'Jupes',
                  path: '/category/womens/bottoms/skirts/?isDiscounted=true',
                },
                {
                  label: 'Robes d’été',
                  path: '/category/womens/dresses/summer-dresses/?isDiscounted=true',
                },
                {
                  label: 'Shorts',
                  path: '/category/womens/bottoms/shorts/?isDiscounted=true',
                },
                {
                  label: 'Baskets',
                  path: '/category/womens/shoes/sneakers/?isDiscounted=true',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.CategoryList,
            title: `Promotions sur les vêtements pour hommes`,
            footer: {
              label: `Alle ansehen`,
              path: '/category/mens/?isDiscounted=true',
            },
            itemsCollection: {
              items: [
                {
                  label: 'Hauts',
                  path: '/category/mens/tops/?isDiscounted=true',
                },
                {
                  label: 'Bas',
                  path: '/category/mens/bottoms/?isDiscounted=true',
                },
                {
                  label: 'Vêtements d’extérieur',
                  path: '/category/mens/outerwear/?isDiscounted=true',
                },
                {
                  label: 'Chaussures',
                  path: '/category/mens/shoes/?isDiscounted=true',
                },
                {
                  label: 'Accessories',
                  path: '/category/mens/accessories/?isDiscounted=true',
                },
                {
                  label: 'T-shirts',
                  path: '/category/mens/tops/t-shirts/?isDiscounted=true',
                },
                {
                  label: 'Sweats à capuche',
                  path: '/category/mens/tops/hoodies/?isDiscounted=true',
                },
                {
                  label: 'Sweat-shirts',
                  path: '/category/mens/tops/sweatshirts/?isDiscounted=true',
                },
                {
                  label: 'Jeans',
                  path: '/category/mens/bottoms/jeans/?isDiscounted=true',
                },
                {
                  label: 'Shorts',
                  path: '/category/mens/bottoms/shorts/?isDiscounted=true',
                },
                {
                  label: 'Baskets',
                  path: '/category/mens/shoes/sneakers/?isDiscounted=true',
                },
                {
                  label: 'Chapeaux',
                  path: '/category/mens/accessories/hats/?isDiscounted=true',
                },
              ],
            },
          },
        ],
      },
    },
  ];
}

function MEGANAV_DE(cloudfrontHost: string): SanitisedMegaNav {
  return [
    {
      label: 'Herrenmode',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Einkaufen nach Kategorie',
            footer: { label: 'Alle ansehen', path: '/category/mens/' },
            itemsCollection: {
              items: [
                { label: 'Tops', path: '/category/mens/tops/' },
                { label: 'Unterteile', path: '/category/mens/bottoms/' },
                { label: 'Oberbekleidung', path: '/category/mens/outerwear/' },
                { label: 'Schuhe', path: '/category/mens/shoes/' },
                { label: 'Accessoires', path: '/category/mens/accessories/' },
                { label: 'Schmuck', path: '/category/jewellery/' },
                { label: 'T-shirts', path: '/category/mens/tops/t-shirts/' },
                {
                  label: 'Verkauf',
                  path: '/category/mens/?isDiscounted=true',
                  colourOverride: 'red',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Vorgestellt',
            itemsCollection: {
              items: [
                { label: 'Hoodies', path: '/category/mens/tops/hoodies/' },
                {
                  label: 'Sweatshirts',
                  path: '/category/mens/tops/sweatshirts/',
                },
                { label: 'Jeans', path: '/category/mens/bottoms/jeans/' },
                { label: 'Shorts', path: '/category/mens/bottoms/shorts/' },
                { label: 'Sneakers', path: '/category/mens/shoes/sneakers/' },
                {
                  label: 'Hüte/Mützen',
                  path: '/category/mens/accessories/hats/',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Tops',
                  path: '/category/mens/tops/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/tops.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/tops.webp`,
                  },
                },
                {
                  label: 'Shorts',
                  path: '/category/mens/bottoms/shorts',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/shorts.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/shorts.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Womenswear',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Einkaufen nach Kategorie',
            footer: { label: 'Alle ansehen', path: '/category/womens/' },
            itemsCollection: {
              items: [
                { label: 'Tops', path: '/category/womens/tops/' },
                { label: 'Unterteile', path: '/category/womens/bottoms/' },
                { label: 'Kleider', path: '/category/womens/dresses/' },
                {
                  label: 'Oberbekleidung',
                  path: '/category/womens/outerwear/',
                },
                { label: 'Schuhe', path: '/category/womens/shoes/' },
                { label: 'Accessoires', path: '/category/womens/accessories/' },
                { label: 'Schmuck', path: '/category/jewellery/' },
                {
                  label: 'Verkauf',
                  path: '/category/womens/?isDiscounted=true',
                  colourOverride: 'red',
                },
              ],
            },
          },
          {
            title: 'Vorgestellt',
            __typename: MegaNavContentType.CategoryList,
            itemsCollection: {
              items: [
                {
                  label: 'Crop-Tops',
                  path: '/category/womens/tops/crop-tops/',
                },
                { label: 'T-shirts', path: '/category/womens/tops/t-shirts/' },
                { label: 'Shorts', path: '/category/womens/bottoms/shorts/' },
                { label: 'Röcke', path: '/category/womens/bottoms/skirts/' },
                { label: 'Sneakers', path: '/category/womens/shoes/sneakers/' },
                {
                  label: 'Taschen & Handtaschen',
                  path: '/category/womens/accessories/bags-and-purses/',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Neckholder-Top',
                  path: '/search/?q=halter+top&categories=9&sort=relevance',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/halter_top.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/halter_top.webp`,
                  },
                },
                {
                  label: 'Boots',
                  path: '/category/womens/shoes/boots/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/boots.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/boots.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Marken',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Beliebte marken',
            footer: { label: 'Alle ansehen', path: '/brands/' },
            itemsCollection: {
              items: [
                { label: 'Adidas', path: '/brands/adidas/' },
                { label: 'Brandy Melville', path: '/brands/brandy-melville/' },
                { label: 'Carhartt', path: '/brands/carhartt/' },
                { label: 'Converse', path: '/brands/converse/' },
                { label: 'Corteiz', path: '/brands/corteiz/' },
                { label: 'Dr. Martens', path: '/brands/dr-martens/' },
                { label: 'Free People', path: '/brands/free-people/' },
                { label: 'Gymshark', path: '/brands/gymshark/' },
                { label: 'H&M', path: '/brands/h-m/' },
                { label: 'Jordan', path: '/brands/jordan/' },
                { label: `Levi's`, path: '/brands/levi-s/' },
                { label: 'Lululemon', path: '/brands/lululemon/' },
                { label: 'New Balance', path: '/brands/new-balance/' },
                { label: 'Nike', path: '/brands/nike/' },
                { label: 'Patagonia', path: '/brands/patagonia/' },
                { label: 'Stüssy', path: '/brands/stussy/' },
                {
                  label: 'Urban Outfitters',
                  path: '/brands/urban-outfitters/',
                },
                { label: 'Zara', path: '/brands/zara/' },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Carhartt',
                  path: '/brands/carhartt/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/carhartt.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/carhartt.webp`,
                  },
                },
                {
                  label: 'New Balance',
                  path: '/brands/new-balance/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/new_balance.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/gb/mobile/new_balance.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Verkauf',
      colourOverride: 'red',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: `Damenmode im Sale`,
            footer: {
              label: `Alle ansehen`,
              path: '/category/womens/?isDiscounted=true',
            },
            itemsCollection: {
              items: [
                {
                  label: 'Tops',
                  path: '/category/womens/tops/?isDiscounted=true',
                },
                {
                  label: 'Unterteile',
                  path: '/category/womens/bottoms/?isDiscounted=true',
                },
                {
                  label: 'Kleider',
                  path: '/category/womens/dresses/?isDiscounted=true',
                },
                {
                  label: 'Oberbekleidung',
                  path: '/category/womens/outerwear/?isDiscounted=true',
                },
                {
                  label: 'Schuhe',
                  path: '/category/womens/shoes/?isDiscounted=true',
                },
                {
                  label: 'Accessoires',
                  path: '/category/womens/accessories/?isDiscounted=true',
                },
                {
                  label: 'Crop-Tops',
                  path: '/category/womens/tops/crop-tops/?isDiscounted=true',
                },
                {
                  label: 'T-shirts',
                  path: '/category/womens/tops/t-shirts/?isDiscounted=true',
                },
                {
                  label: 'Röcke',
                  path: '/category/womens/bottoms/skirts/?isDiscounted=true',
                },
                {
                  label: 'Sommerkleider',
                  path: '/category/womens/dresses/summer-dresses/?isDiscounted=true',
                },
                {
                  label: 'Shorts',
                  path: '/category/womens/bottoms/shorts/?isDiscounted=true',
                },
                {
                  label: 'Sneakers',
                  path: '/category/womens/shoes/sneakers/?isDiscounted=true',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.CategoryList,
            title: `Herremode im Sale`,
            footer: {
              label: `Alle ansehen`,
              path: '/category/mens/?isDiscounted=true',
            },
            itemsCollection: {
              items: [
                {
                  label: 'Tops',
                  path: '/category/mens/tops/?isDiscounted=true',
                },
                {
                  label: 'Unterteile',
                  path: '/category/mens/bottoms/?isDiscounted=true',
                },
                {
                  label: 'Oberbekleidung',
                  path: '/category/mens/outerwear/?isDiscounted=true',
                },
                {
                  label: 'Schuhe',
                  path: '/category/mens/shoes/?isDiscounted=true',
                },
                {
                  label: 'Accessoires',
                  path: '/category/mens/accessories/?isDiscounted=true',
                },
                {
                  label: 'T-shirts',
                  path: '/category/mens/tops/t-shirts/?isDiscounted=true',
                },
                {
                  label: 'Hoodies',
                  path: '/category/mens/tops/hoodies/?isDiscounted=true',
                },
                {
                  label: 'Sweatshirts',
                  path: '/category/mens/tops/sweatshirts/?isDiscounted=true',
                },
                {
                  label: 'Jeans',
                  path: '/category/mens/bottoms/jeans/?isDiscounted=true',
                },
                {
                  label: 'Shorts',
                  path: '/category/mens/bottoms/shorts/?isDiscounted=true',
                },
                {
                  label: 'Sneakers',
                  path: '/category/mens/shoes/sneakers/?isDiscounted=true',
                },
                {
                  label: 'Hüte/Mützen',
                  path: '/category/mens/accessories/hats/?isDiscounted=true',
                },
              ],
            },
          },
        ],
      },
    },
  ];
}

function MEGANAV_US(cloudfrontHost: string): SanitisedMegaNav {
  return [
    {
      label: 'Menswear',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Shop by category',
            footer: { label: 'See all menswear', path: '/category/mens/' },
            itemsCollection: {
              items: [
                { label: 'Tops', path: '/category/mens/tops/' },
                { label: 'Bottoms', path: '/category/mens/bottoms/' },
                { label: 'Coats & Jackets', path: '/category/mens/outerwear/' },
                { label: 'Shoes', path: '/category/mens/shoes/' },
                { label: 'Accessories', path: '/category/mens/accessories/' },
                { label: 'Jewelry', path: '/category/jewellery/' },
                { label: 'T-shirts', path: '/category/mens/tops/t-shirts/' },
                {
                  label: 'Sale',
                  path: '/category/mens/?isDiscounted=true',
                  colourOverride: 'red',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Featured',
            itemsCollection: {
              items: [
                { label: 'Hoodies', path: '/category/mens/tops/hoodies/' },
                {
                  label: 'Sweatshirts',
                  path: '/category/mens/tops/sweatshirts/',
                },
                { label: 'Shirts', path: '/category/mens/tops/casual-shirts/' },
                { label: 'Jeans', path: '/category/mens/bottoms/jeans/' },
                {
                  label: 'Puffer jackets',
                  path: '/category/mens/outerwear/puffer-jackets/',
                },
                { label: 'Hats', path: '/category/mens/accessories/hats/' },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Sneakers',
                  path: '/category/mens/shoes/sneakers',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/sneakers.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/mobile/sneakers_mobile.webp`,
                  },
                },
                {
                  label: 'Cardigans',
                  path: '/category/mens/tops/cardigans/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/cardigans.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/mobile/cardigan_mobile.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Womenswear',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Shop by category',
            footer: { label: 'See all womenswear', path: '/category/womens/' },
            itemsCollection: {
              items: [
                { label: 'Tops', path: '/category/womens/tops/' },
                { label: 'Bottoms', path: '/category/womens/bottoms/' },
                { label: 'Dresses', path: '/category/womens/dresses/' },
                {
                  label: 'Coats & Jackets',
                  path: '/category/womens/outerwear/',
                },
                { label: 'Shoes', path: '/category/womens/shoes/' },
                { label: 'Accessories', path: '/category/womens/accessories/' },
                { label: 'Jewelry', path: '/category/jewellery/' },
                {
                  label: 'Sale',
                  path: '/category/womens/?isDiscounted=true',
                  colourOverride: 'red',
                },
              ],
            },
          },
          {
            title: 'Featured',
            __typename: MegaNavContentType.CategoryList,
            itemsCollection: {
              items: [
                {
                  label: 'Leather jackets',
                  path: '/category/womens/outerwear/leather-jackets',
                },
                { label: 'T-shirts', path: '/category/womens/tops/t-shirts/' },
                { label: 'Sweaters', path: '/category/womens/tops/sweaters/' },
                { label: 'Skirts', path: '/category/womens/bottoms/skirts/' },
                {
                  label: 'Casual dresses',
                  path: '/category/womens/dresses/casual-dresses/',
                },
                { label: 'Sneakers', path: '/category/womens/shoes/sneakers/' },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Ballet flats',
                  path: '/search/?q=ballet+flats&categories=14&sort=relevance',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/ballet_flats.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/mobile/ballet_flats_mobile.webp`,
                  },
                },
                {
                  label: 'Bags & Purses',
                  path: '/category/womens/accessories/bags-and-purses/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/bags_and_purses.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/mobile/bags_and_purses_mobile.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Brands',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Popular brands',
            footer: { label: 'See all brands', path: '/brands/' },
            itemsCollection: {
              items: [
                { label: 'Adidas', path: '/brands/adidas/' },
                { label: 'Aritzia', path: '/brands/aritzia/' },
                { label: 'Brandy Melville', path: '/brands/brandy-melville/' },
                { label: 'Carhartt', path: '/brands/carhartt/' },
                { label: 'Dickies', path: '/brands/dickies/' },
                { label: 'Dr. Martens', path: '/brands/dr-martens/' },
                { label: 'Free People', path: '/brands/free-people/' },
                { label: 'Harley Davidson', path: '/brands/harley-davidson/' },
                { label: 'Jordan', path: '/brands/jordan/' },
                { label: 'Lululemon', path: '/brands/lululemon/' },
                { label: 'New Balance', path: '/brands/new-balance/' },
                { label: 'Nike', path: '/brands/nike/' },
                { label: 'Patagonia', path: '/brands/patagonia/' },
                {
                  label: 'Polo Ralph Lauren',
                  path: '/brands/polo-ralph-lauren/',
                },
                { label: 'Supreme', path: '/brands/supreme/' },
                { label: 'The North Face', path: '/brands/the-north-face/' },
                {
                  label: 'Urban Outfitters',
                  path: '/brands/urban-outfitters/',
                },
                { label: 'Zara', path: '/brands/zara/' },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Levi’s',
                  path: '/brands/levi-s/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/levi.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/mobile/levi.webp`,
                  },
                },
                {
                  label: 'Stüssy',
                  path: '/brands/stussy/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/stussy_trousers.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/mobile/stussy_trousers.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Sale',
      colourOverride: 'red',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: `Women's sale`,
            footer: {
              label: `See all women's sale`,
              path: '/category/womens/?isDiscounted=true',
            },
            itemsCollection: {
              items: [
                {
                  label: 'Tops',
                  path: '/category/womens/tops/?isDiscounted=true',
                },
                {
                  label: 'Bottoms',
                  path: '/category/womens/bottoms/?isDiscounted=true',
                },
                {
                  label: 'Dresses',
                  path: '/category/womens/dresses/?isDiscounted=true',
                },
                {
                  label: 'Coats & Jackets',
                  path: '/category/womens/outerwear/?isDiscounted=true',
                },
                {
                  label: 'Shoes',
                  path: '/category/womens/shoes/?isDiscounted=true',
                },
                {
                  label: 'Accessories',
                  path: '/category/womens/accessories/?isDiscounted=true',
                },
                {
                  label: 'T-shirts',
                  path: '/category/womens/tops/t-shirts/?isDiscounted=true',
                },
                {
                  label: 'Blouses',
                  path: '/category/womens/tops/blouses/?isDiscounted=true',
                },
                {
                  label: 'Skirts',
                  path: '/category/womens/bottoms/skirts/?isDiscounted=true',
                },
                {
                  label: 'Leather jackets',
                  path: '/category/womens/outerwear/leather-jackets/?isDiscounted=true',
                },
                {
                  label: 'Sneakers',
                  path: '/category/womens/shoes/sneakers/?isDiscounted=true',
                },
                {
                  label: 'Boots',
                  path: '/category/womens/shoes/boots/?isDiscounted=true',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.CategoryList,
            title: `Men's sale`,
            footer: {
              label: `See all men's sale`,
              path: '/category/mens/?isDiscounted=true',
            },
            itemsCollection: {
              items: [
                {
                  label: 'Tops',
                  path: '/category/mens/tops/?isDiscounted=true',
                },
                {
                  label: 'Bottoms',
                  path: '/category/mens/bottoms/?isDiscounted=true',
                },
                {
                  label: 'Coats & Jackets',
                  path: '/category/mens/outerwear/?isDiscounted=true',
                },
                {
                  label: 'Shoes',
                  path: '/category/mens/shoes/?isDiscounted=true',
                },
                {
                  label: 'Accessories',
                  path: '/category/mens/accessories/?isDiscounted=true',
                },
                {
                  label: 'T-shirts',
                  path: '/category/mens/tops/t-shirts/?isDiscounted=true',
                },
                {
                  label: 'Sweaters',
                  path: '/category/mens/tops/sweatshirts/?isDiscounted=true',
                },
                {
                  label: 'Casual pants',
                  path: '/category/mens/bottoms/casual-trousers/?isDiscounted=true',
                },
                {
                  label: 'Jeans',
                  path: '/category/mens/bottoms/jeans/?isDiscounted=true',
                },
                {
                  label: 'Windbreakers',
                  path: '/category/mens/outerwear/windbreakers/?isDiscounted=true',
                },
                {
                  label: 'Sneakers',
                  path: '/category/mens/shoes/sneakers/?isDiscounted=true',
                },
                {
                  label: 'Boots',
                  path: '/category/mens/shoes/boots/?isDiscounted=true',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Under $100',
                  path: '/explore/sale-under-usd100',
                  hideLabel: true,
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/sale_100_us.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/sale_100_us.webp`,
                  },
                },
                {
                  label: 'Under $50',
                  path: '/explore/sale-under-usd50',
                  hideLabel: true,
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/sale_50_us.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/sale_50_us.webp`,
                  },
                },
                {
                  label: 'Under $20',
                  path: '/explore/sale-under-usd20',
                  hideLabel: true,
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/sale_20_us.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/sale_20_us.webp`,
                  },
                },
                {
                  label: 'Under $10',
                  path: '/explore/sale-under-usd10',
                  hideLabel: true,
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/sale_10_us.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/us/sale_10_us.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ];
}

function MEGANAV_AU(cloudfrontHost: string): SanitisedMegaNav {
  return [
    {
      label: 'Menswear',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Shop by category',
            footer: { label: 'See all menswear', path: '/category/mens/' },
            itemsCollection: {
              items: [
                { label: 'Tops', path: '/category/mens/tops/' },
                { label: 'Bottoms', path: '/category/mens/bottoms/' },
                { label: 'Coats & Jackets', path: '/category/mens/outerwear/' },
                { label: 'Shoes', path: '/category/mens/shoes/' },
                { label: 'Accessories', path: '/category/mens/accessories/' },
                { label: 'Jewellery', path: '/category/jewellery/' },
                {
                  label: 'Sunglasses',
                  path: '/category/mens/accessories/sunglasses/',
                },
                {
                  label: 'Sale',
                  path: '/category/mens/?isDiscounted=true',
                  colourOverride: 'red',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Featured',
            itemsCollection: {
              items: [
                { label: 'Vests', path: '/category/mens/outerwear/vests/' },
                { label: 'Jerseys', path: '/category/mens/tops/jerseys/' },
                { label: 'Jeans', path: '/category/mens/bottoms/jeans/' },
                { label: 'Bags', path: '/category/mens/accessories/bags/' },
                { label: 'Sneakers', path: '/category/mens/shoes/sneakers/' },
                { label: 'Hats', path: '/category/mens/accessories/hats/' },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Shorts',
                  path: '/category/mens/bottoms/shorts/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/shorts.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/mobile/mens_shorts.webp`,
                  },
                },
                {
                  label: 'T-shirts',
                  path: '/category/mens/tops/t-shirts/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/t_shirt.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/mobile/mens_t_shirt.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Womenswear',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Shop by category',
            footer: { label: 'See all womenswear', path: '/category/womens/' },
            itemsCollection: {
              items: [
                { label: 'Tops', path: '/category/womens/tops/' },
                { label: 'Bottoms', path: '/category/womens/bottoms/' },
                { label: 'Dresses', path: '/category/womens/dresses/' },
                {
                  label: 'Coats & Jackets',
                  path: '/category/womens/outerwear/',
                },
                { label: 'Shoes', path: '/category/womens/shoes/' },
                { label: 'Accessories', path: '/category/womens/accessories/' },
                { label: 'Jewellery', path: '/category/jewellery/' },
                {
                  label: 'Sale',
                  path: '/category/womens/?isDiscounted=true',
                  colourOverride: 'red',
                },
              ],
            },
          },
          {
            title: 'Featured',
            __typename: MegaNavContentType.CategoryList,
            itemsCollection: {
              items: [
                {
                  label: 'Crop tops',
                  path: '/category/womens/tops/crop-tops/',
                },
                { label: 'T-shirts', path: '/category/womens/tops/t-shirts/' },
                { label: 'Skirts', path: '/category/womens/bottoms/skirts/' },
                {
                  label: 'Sunglasses',
                  path: '/category/womens/accessories/sunglasses/',
                },
                { label: 'Sandals', path: '/category/womens/shoes/sandals/' },
                {
                  label: 'Bags & Purses',
                  path: '/category/womens/accessories/bags-and-purses/',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Dresses',
                  path: '/category/womens/dresses/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/dresses.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/mobile/dresses_mobile.webp`,
                  },
                },
                {
                  label: 'Sneakers',
                  path: '/category/womens/shoes/sneakers/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/sneakers.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/mobile/sneakers_mobile.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Brands',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: 'Popular brands',
            footer: { label: 'See all brands', path: '/brands/' },
            itemsCollection: {
              items: [
                { label: 'Adidas', path: '/brands/adidas/' },
                { label: 'Zimmermann', path: '/brands/zimmermann/' },
                { label: 'Billabong', path: '/brands/billabong/' },
                { label: 'Carhartt', path: '/brands/carhartt/' },
                { label: 'Dickies', path: '/brands/dickies/' },
                { label: 'Dr. Martens', path: '/brands/dr-martens/' },
                { label: 'KOOKAÏ', path: '/brands/kookai/' },
                { label: `Levi's`, path: '/brands/levi-s/' },
                { label: 'Lululemon', path: '/brands/lululemon/' },
                { label: 'Converse', path: '/brands/converse/' },
                { label: 'New Balance', path: '/brands/new-balance/' },
                {
                  label: 'Polo Ralph Lauren',
                  path: '/brands/polo-ralph-lauren/',
                },
                { label: 'Princess Polly', path: '/brands/princess-polly/' },
                { label: 'Réalisation Par', path: '/brands/realisation-par/' },
                { label: 'SIR.', path: '/brands/sir/' },
                { label: 'The North Face', path: '/brands/the-north-face/' },
                { label: `With Jéan`, path: '/brands/with-jean/' },
                { label: 'Zara', path: '/brands/zara/' },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Bec & Bridge',
                  path: '/brands/bec-bridge/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/bec_and_bridge.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/mobile/bec_and_bridge_mobile.webp`,
                  },
                },
                {
                  label: 'Nike',
                  path: '/brands/nike/',
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/nike.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/mobile/nike_mobile.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: 'Sale',
      colourOverride: 'red',
      contentCollection: {
        items: [
          {
            __typename: MegaNavContentType.CategoryList,
            title: `Women's sale`,
            footer: {
              label: `See all women's sale`,
              path: '/category/womens/?isDiscounted=true',
            },
            itemsCollection: {
              items: [
                {
                  label: 'Tops',
                  path: '/category/womens/tops/?isDiscounted=true',
                },
                {
                  label: 'Bottoms',
                  path: '/category/womens/bottoms/?isDiscounted=true',
                },
                {
                  label: 'Dresses',
                  path: '/category/womens/dresses/?isDiscounted=true',
                },
                {
                  label: 'Coats & Jackets',
                  path: '/category/womens/outerwear/?isDiscounted=true',
                },
                {
                  label: 'Shoes',
                  path: '/category/womens/shoes/?isDiscounted=true',
                },
                {
                  label: 'Accessories',
                  path: '/category/womens/accessories/?isDiscounted=true',
                },
                {
                  label: 'T-shirts',
                  path: '/category/womens/tops/t-shirts/?isDiscounted=true',
                },
                {
                  label: 'Bags & Purses',
                  path: '/category/womens/accessories/bags-and-purses/?isDiscounted=true',
                },
                {
                  label: 'Skirts',
                  path: '/category/womens/bottoms/skirts/?isDiscounted=true',
                },
                {
                  label: 'Casual dresses',
                  path: '/category/womens/dresses/casual-dresses/?isDiscounted=true',
                },
                {
                  label: 'Sneakers',
                  path: '/category/womens/shoes/sneakers/?isDiscounted=true',
                },
                {
                  label: 'Sandals',
                  path: '/category/womens/shoes/sandals/?isDiscounted=true',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.CategoryList,
            title: `Men's sale`,
            footer: {
              label: `See all men's sale`,
              path: '/category/mens/?isDiscounted=true',
            },
            itemsCollection: {
              items: [
                {
                  label: 'Tops',
                  path: '/category/mens/tops/?isDiscounted=true',
                },
                {
                  label: 'Bottoms',
                  path: '/category/mens/bottoms/?isDiscounted=true',
                },
                {
                  label: 'Coats & Jackets',
                  path: '/category/mens/outerwear/?isDiscounted=true',
                },
                {
                  label: 'Shoes',
                  path: '/category/mens/shoes/?isDiscounted=true',
                },
                {
                  label: 'Accessories',
                  path: '/category/mens/accessories/?isDiscounted=true',
                },
                {
                  label: 'T-shirts',
                  path: '/category/mens/tops/t-shirts/?isDiscounted=true',
                },
                {
                  label: 'Shirts',
                  path: '/category/mens/tops/casual-shirts/?isDiscounted=true',
                },
                {
                  label: 'Jeans',
                  path: '/category/mens/bottoms/jeans/?isDiscounted=true',
                },
                {
                  label: 'Shorts',
                  path: '/category/mens/bottoms/shorts/?isDiscounted=true',
                },
                {
                  label: 'Sneakers',
                  path: '/category/mens/shoes/sneakers/?isDiscounted=true',
                },
                {
                  label: 'Vests',
                  path: '/category/mens/outerwear/vests/?isDiscounted=true',
                },
                {
                  label: 'Hats',
                  path: '/category/mens/accessories/hats/?isDiscounted=true',
                },
              ],
            },
          },
          {
            __typename: MegaNavContentType.ImageLinkList,
            itemsCollection: {
              items: [
                {
                  label: 'Under A$100',
                  path: '/explore/sale-under-ausd100',
                  hideLabel: true,
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/sale_100_au.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/sale_100_au.webp`,
                  },
                },
                {
                  label: 'Under A$50',
                  path: '/explore/sale-under-ausd50',
                  hideLabel: true,
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/sale_50_au.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/sale_50_au.webp`,
                  },
                },
                {
                  label: 'Under A$20',
                  path: '/explore/sale-under-ausd20',
                  hideLabel: true,
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/sale_20_au.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/sale_20_au.webp`,
                  },
                },
                {
                  label: 'Under A$10',
                  path: '/explore/sale-under-ausd10',
                  hideLabel: true,
                  imageDesktop: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/sale_10_au.webp`,
                  },
                  imageMobile: {
                    url: `${cloudfrontHost}/web/assets/meganav/au/sale_10_au.webp`,
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ];
}

export const MEGANAV_CONFIGS = {
  gb: MEGANAV_UK,
  it: MEGANAV_IT,
  fr: MEGANAV_FR,
  de: MEGANAV_DE,
  us: MEGANAV_US,
  au: MEGANAV_AU,
};

export const CONTENTFUL_NAV_ID: Record<SupportedLocation, string> = {
  [SupportedLocation.GB]: '3I7jrys3ERms1F5eakM6Gb',
  [SupportedLocation.IT]: '49IfYQGqrTGngKH94MLDpt',
  [SupportedLocation.FR]: '1ZMImYPYseIW4YfxzhGZci',
  [SupportedLocation.DE]: '1TrhBygL4tKhfsn8fMDYm',
  [SupportedLocation.US]: '59LC8awkBBYyLUKEI4oK8Q',
  [SupportedLocation.AU]: '205qfb47C5sY05C2TjbfeC',
  [SupportedLocation.IE]: '3I7jrys3ERms1F5eakM6Gb', // fallback to GB
  [SupportedLocation.CA]: '59LC8awkBBYyLUKEI4oK8Q', // fallback to US
  [SupportedLocation.NZ]: '205qfb47C5sY05C2TjbfeC', // fallback to AU
};
