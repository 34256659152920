import { useQuery } from '@tanstack/react-query';

import { isNotNull } from '../cms/helpers/isNotNull';

import { getContentfulNavId, getFallbackNavConfig } from './utils';
import { MegaNav } from './types';
import { fetchNavigation } from './api';

import { useCurrentLocation } from '@/modules/location/useCurrentLocation';
import { useCurrentLanguage } from '@/modules/language/useCurrentLanguage';
import { RQ_NAV_CONFIG } from '@/modules/ReactQuery/cacheKeys';
import { SupportedLocation } from '@/modules/location/constants';

export function useNavConfig() {
  const { location } = useCurrentLocation();
  const { language } = useCurrentLanguage();

  const { data } = useQuery<MegaNav>({
    queryKey: [`${RQ_NAV_CONFIG}-${language}-${location}`],
    queryFn: () =>
      fetchNavigation(
        getContentfulNavId(location as SupportedLocation, language)
      ),
  });

  const sanitisedMeganav = data?.filter(isNotNull);

  return sanitisedMeganav?.length
    ? sanitisedMeganav
    : getFallbackNavConfig(location as SupportedLocation, language);
}
