import { Nullable, ContentfulItemCollection } from '../cms/types';

export enum MegaNavContentType {
  CategoryList = 'WebNavCategory',
  ImageLinkList = 'WebNavImageLinkList',
}

export type MegaNav = Array<Nullable<MegaNavItem>>;
export type SanitisedMegaNav = Array<MegaNavItem>;

export type MegaNavItem = {
  label: string;
  colourOverride?: Nullable<string>;
  contentCollection: ContentfulItemCollection<CategoryList | ImageLinkList>;
};

// Content types
export type CategoryList = {
  __typename: MegaNavContentType.CategoryList;
  title: string;
  itemsCollection: ContentfulItemCollection<CategoryOption>;
  footer?: Nullable<CategoryOption>;
};

export type ImageLinkList = {
  __typename: MegaNavContentType.ImageLinkList;
  title?: Nullable<string>;
  itemsCollection: ContentfulItemCollection<ImageLink>;
};

// Atoms
export type CategoryOption = {
  label: string;
  path: string;
  colourOverride?: Nullable<string>;
};

export type ImageLinkImage = null | {
  url: string;
  description?: Nullable<string>;
};

export type ImageLink = {
  label: string;
  path: string | null;
  extendedPath?: string | null;
  hideLabel?: Nullable<boolean>;
  imageDesktop: ImageLinkImage;
  imageMobile: ImageLinkImage;
};
