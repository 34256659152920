import { MegaNav } from './types';

import { CONTENTFUL_ENDPOINT } from '@/constants/endpoints';
import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types';

export function fetchNavigation(id: string): Promise<MegaNav> {
  const requestBody = {
    query: `query webNavEntryQuery {
      webNav(id: "${id}") {
        title
        itemsCollection {
          items {
            label
            colourOverride
            contentCollection {
              items {
                __typename
                ... on WebNavCategory {
                  title
                  itemsCollection {
                    items {
                      label
                      path
                      colourOverride
                    }
                  }
                  footer {
                    label
                    path
                    colourOverride
                  }
                }
                ... on WebNavImageLinkList {
                  title
                  itemsCollection {
                    items {
                      label
                      hideLabel
                      path
                      extendedPath
                      imageDesktop {
                        url
                        description
                      }
                      imageMobile {
                        url
                        description
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`,
  };

  return (
    http
      .post(CONTENTFUL_ENDPOINT + `?id=${id}`, requestBody, {
        cache: 'force-cache',
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: AxiosCompatibleResponse<any>) => {
        return res.data.data.webNav.itemsCollection.items;
      })
  );
}
