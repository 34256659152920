import { MEGANAV_CONFIGS, CONTENTFUL_NAV_ID } from './constants';
import { SanitisedMegaNav } from './types';

import { SupportedLocations } from '@/modules/location/constants';

export function getFallbackNavConfig(
  locale: SupportedLocations,
  language: string
): SanitisedMegaNav {
  const cloudfrontHost = process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST;
  if (language === 'it') {
    return MEGANAV_CONFIGS.it(cloudfrontHost);
  }

  if (language === 'fr') {
    return MEGANAV_CONFIGS.fr(cloudfrontHost);
  }

  if (language === 'de') {
    return MEGANAV_CONFIGS.de(cloudfrontHost);
  }

  if (locale === 'us' || locale === 'ca') {
    return MEGANAV_CONFIGS.us(cloudfrontHost);
  }

  if (locale === 'au' || locale === 'nz') {
    return MEGANAV_CONFIGS.au(cloudfrontHost);
  }

  return MEGANAV_CONFIGS.gb(cloudfrontHost);
}

export function getContentfulNavId(
  locale: SupportedLocations,
  language: string
) {
  if (language === 'it') {
    return CONTENTFUL_NAV_ID.it;
  }

  if (language === 'fr') {
    return CONTENTFUL_NAV_ID.fr;
  }

  if (language === 'de') {
    return CONTENTFUL_NAV_ID.de;
  }

  if (locale === 'us') {
    return CONTENTFUL_NAV_ID.us;
  }

  if (locale === 'au') {
    return CONTENTFUL_NAV_ID.au;
  }

  if (locale === 'ie') {
    return CONTENTFUL_NAV_ID.ie;
  }

  if (locale === 'ca') {
    return CONTENTFUL_NAV_ID.ca;
  }

  if (locale === 'nz') {
    return CONTENTFUL_NAV_ID.nz;
  }

  return CONTENTFUL_NAV_ID.gb;
}
